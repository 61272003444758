<template>
  <v-row justify="center" class="primary-text">
    <v-col cols="12" sm="12" md="10" lg="10" xl="10">
      <v-card class="mb-5">
        <v-container>
          <v-form class="pl-5 pr-5" v-on:submit.prevent="submitForm()">
            <div class="headline pb-1 pt-5">Event Callbacks Configurations</div>
            <v-divider class="ml-5 mb-3"></v-divider>
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-checkbox
                  v-model="localConfig.introConf.eventOnCallConnect"
                  label="Do you want event and callback on call connect?"
                ></v-checkbox>
                <v-text-field
                  v-if="localConfig.introConf.eventOnCallConnect"
                  label="Mention the callback URLs separated by semicolumn"
                  v-model="localConfig.introConf.eventOnCallConnectURL"
                  ref="variable_onCallConnectUrl"
                  :rules="rules.callbackUrls"
                  @input="clearError('eventOnCallConnectURLError')"
                  :error-messages="eventOnCallConnectURLError"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-checkbox
                  v-model="localConfig.introConf.eventOnDrop"
                  label="Do you want event and callback on call drop?"
                ></v-checkbox>
                <v-text-field
                  v-if="localConfig.introConf.eventOnDrop"
                  label="Mention the callback URLs separated by semicolumn"
                  v-model="localConfig.introConf.eventOnDropURL"
                  ref="variable_onCallDropUrl"
                  :rules="rules.callbackUrls"
                  @input="clearError('eventOnDropURLError')"
                  :error-messages="eventOnDropURLError"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-checkbox
                  v-model="localConfig.introConf.eventOnCallMissed"
                  label="Do you want event and callback on call missed?"
                ></v-checkbox>
                <v-text-field
                  v-if="localConfig.introConf.eventOnCallMissed"
                  label="Mention the callback URLs separated by semicolumn"
                  v-model="localConfig.introConf.eventOnCallMissedURL"
                  ref="variable_onCallMissedUrl"
                  :rules="rules.callbackUrls"
                  @input="clearError('eventOnCallMissedURLError')"
                  :error-messages="eventOnCallMissedURLError"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-checkbox
                  v-model="localConfig.introConf.eventOnCallScheduled"
                  label="Do you want event and callback on call scheduled?"
                ></v-checkbox>
                <v-text-field
                  v-if="localConfig.introConf.eventOnCallScheduled"
                  label="Mention the callback URLs separated by semicolumn"
                  v-model="localConfig.introConf.eventOnCallScheduledURL"
                  ref="variable_onCallScheduledUrl"
                  :rules="rules.callbackUrls"
                  @input="clearError('eventOnCallScheduledURLError')"
                  :error-messages="eventOnCallScheduledURLError"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-checkbox
                  v-model="localConfig.introConf.eventOnCallCompleted"
                  label="Do you want event and callback on call completed?"
                ></v-checkbox>
                <v-text-field
                  v-if="localConfig.introConf.eventOnCallCompleted"
                  label="Mention the callback URLs separated by semicolumn"
                  v-model="localConfig.introConf.eventOnCallCompletedURL"
                  ref="variable_onCallCompletedUrl"
                  :rules="rules.callbackUrls"      
                  @input="clearError('eventOnCallCompletedURLError')"
                  :error-messages="eventOnCallCompletedURLError"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-checkbox
                  v-model="localConfig.introConf.allowChangeStatus"
                  label="Do you want to allow change status callback?"
                ></v-checkbox> 
              </v-col>

              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-checkbox
                  v-model="localConfig.introConf.onVideoUploadingDone"
                  label="Do you want callback on video uploading done?"
                ></v-checkbox>
                <v-text-field
                  v-if="localConfig.introConf.onVideoUploadingDone"
                  label="Mention the callback URLs separated by semicolumn"
                  v-model="localConfig.introConf.onVideoUploadingDoneURL"
                  ref="variable_onVideoUploadingDoneUrl"
                  @input="clearError('onVideoUploadingDoneURLError')"
                  :rules="rules.callbackUrls"
                  :error-messages="onVideoUploadingDoneURLError"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-checkbox
                  v-model="localConfig.introConf.onVideoUploadingFailed"
                  label="Do you want callback on video uploading failed?"
                ></v-checkbox>
                <v-text-field
                  v-if="localConfig.introConf.onVideoUploadingFailed"
                  label="Mention the callback URLs separated by semicolumn"
                  v-model="localConfig.introConf.onVideoUploadingFailedURL"
                  ref="variable_onVideoUploadingFailedUrl"
                  @input="clearError('onVideoUploadingFailedURLError')"
                  :rules="rules.callbackUrls"
                  :error-messages="onVideoUploadingFailedURLError"
                ></v-text-field>
              </v-col>
            </v-row>
            <div class="headline pb-1 mt-5">Redirect URL Configurations</div>
            <v-divider class="ml-5 mb-3"></v-divider>
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-checkbox v-model="localConfig.introConf.redirectOnSuccessful" label="Do you want to redirect to an external page on completion?">
                </v-checkbox>
                <v-text-field 
                  v-if="localConfig.introConf.redirectOnSuccessful" 
                  label="Redirection URL"
                  v-model="localConfig.introConf.redirectOnSuccessfulURL" 
                  ref="variable_redirectOnSuccessfulURL" 
                  :rules="rules.redirectUrls"
                >
                </v-text-field>
              </v-col>
            </v-row>
            
            <!-- <div class="headline pb-1">Call Priority</div>
            <v-divider class="ml-5 mb-3"></v-divider>
            <table style="width:100%">
              <tbody>
                <tr v-for="(value, key) in callTypes" :key="key">
                  <td>{{ value }}</td>
                  <td>
                    <v-select v-model="localConfig.callPriority[key]" :items="[1, 2, 3, 4]"></v-select>
                  </td>
                </tr>
              </tbody>
            </table> -->
            <div class="text-right">
              <v-btn class="ma-0" type="submit" color="primary">Update</v-btn>
            </div>
          </v-form>
        </v-container>
        <v-dialog v-model="alertMessage" persistent max-width="550">
          <v-card>
            <v-card-text>
              <p class="subtitle-1 pt-4 mb-0 pb-0">{{message}}</p>
              <v-spacer></v-spacer>
              <div class="text-right">
                <v-btn dark small color="signzy_color" @click="alertMessage = false">Ok</v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
let axiosInstance = window.axiosInstance;
export default {
  data: () => ({
    alertMessage: false,
    dummy: {},
    currentFlow: "",
    localConfig: {},
    message: "",
    languages: ["HINDI", "KANNADA", "BENGALI", "GUJARATI"],
    callTypes: {
      LIVE_PRIORITY: "Live Priority Calls",
      LIVE_NONPRIORITY: "Live Non-Priority Calls",
      SCHEDULED_PRIORITY: "Scheduled Priority Calls",
      SCHEDULED_NONPRIORITY: "Scheduled Non-Priority Calls"
    },
    rules: {
      callbackUrls: [
        (value) => 
          !!value ||
          value.length ||
          "Need a minimum of one url!",
        (value) => {
          let urlList = value.split(";").map(function(url) {
            return url.trim();
          });
          let regex = new RegExp(
            '^([a-zA-Z]+:\\/\\/)?' + // protocol
              '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
              '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
              '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
              '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
              '(\\#[-a-z\\d_]*)?$', // fragment locator
            'i'
          );
          for (let i = 0; i < urlList.length; i++) {
            if (!urlList[i].match(regex)) {
              return "Please provide a valid live URL";
            }
          }
          return true;
        }
      ],
      redirectUrls: [
        (value) =>
          !!value ||
          value.length ||
          "Cannot leave this empty",
        (value) => {
          let regex = new RegExp(
            '^([a-zA-Z]+:\\/\\/)?' + // protocol
              '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
              '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
              '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
              '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
              '(\\#[-a-z\\d_]*)?$', // fragment locator
            'i'
          );
          if (!value.match(regex)) {
            return "Enter a valid live redirect URL!";
          }
          return true;
        }
        
      ]
    },
    eventOnCallConnectURLError: "",
    eventOnDropURLError: "",
    eventOnCallCompletedURLError: "",
    eventOnCallMissedURLError: "",
    eventOnCallScheduledURLError: "",
    onVideoUploadingDoneURLError: "",
    onVideoUploadingFailedURLError: "",
    localCopy: {},
    updatedSuccessfully: false,
  }),
  methods: {
    clearError(fieldName) {
      this[fieldName] = "";
    },
    async pingStatus(url) {
      try {
        const result = await axiosInstance.get(url);
        return result.status === 200;
      } catch (error) {
        return false;
      }
    },
    async urlChecker(url, fieldName) {
      let regex = new RegExp(
        '^([a-zA-Z]+:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$' + // fragment locator
        'i'
      );

      try {
        if (regex.test(url)) {
          return true;
        }
        if((await this.pingStatus(url))) {
          this[`${fieldName}Error`] = "";
          return true;
        } else {
          this[`${fieldName}Error`] = "URL is not reachable";
        }
        return false;
      } catch (error) {
        return false;
      }
    },
    async validator(ref, fieldName, typeOfValidation) {
      if (typeOfValidation === "customDomainMatch") {
        if (ref.value === "" || ref.value.length === 0) {
          return false;
        }
        if (await this.urlChecker(ref.value, fieldName)) {
          return false;
        }
        return true;
      } else if (typeOfValidation === "checkForUrls") {
        if (!ref.value || ref.value.length < 1) {
          return true;
        }
        let urls = ref.value;
        let urlList = urls.split(";").map(function(url) {
          return url.trim();
        });
        for (let i = 0; i < urlList.length; i++) {
          if (await this.urlChecker(urlList[i], fieldName)) {
            return false;
          }
        }
        return true;
      } else if (typeOfValidation === "redirectUrl") {
        if (ref.value === "" || ref.value.length === 0) {
          return true;
        }
      } else {
        return false;
      }
    },
    getFlowData(){
      if(this.currentFlow != "default"){
        this.localConfig = this.config.multiflow[this.currentFlow];
        this.localCopy.introConf = JSON.parse(JSON.stringify(this.localConfig.introConf));   // creating a deep copy of config for local referencing
      } else{
        this.localConfig = this.config;
        this.localCopy.introConf = JSON.parse(JSON.stringify(this.config.introConf));   // creating a deep copy of config for local referencing
      } 
    },
    highlighter(element) {
      // element passed is a ref
      element.focus();
      element.blur();
    },
    async submitForm() {
        eventBus.$emit("vueLoaderTextChange", "Checking liveness of URLs..");
        eventBus.$emit("vueLoader", true);
        if (
          this.localConfig.introConf.eventOnDrop &&
          await this.validator(this.$refs.variable_onCallDropUrl, 'eventOnDropURL' , "checkForUrls")
        ) {
          this.highlighter(this.$refs.variable_onCallDropUrl);
        } else if (
          this.localConfig.introConf.eventOnCallConnect &&
          await this.validator(this.$refs.variable_onCallConnectUrl, 'eventOnCallConnectURL', "checkForUrls")
        ) {
          this.highlighter(this.$refs.variable_onCallConnectUrl);
        } else if (
          this.localConfig.introConf.eventOnCallCompleted &&
          await this.validator(this.$refs.variable_onCallCompletedUrl,'eventOnCallCompletedURL' , "checkForUrls")
        ) {
          this.highlighter(this.$refs.variable_onCallCompletedUrl);
        } else if (
          this.localConfig.introConf.eventOnCallScheduled &&
          await this.validator(this.$refs.variable_onCallScheduledUrl, 'eventOnCallScheduledURL', "checkForUrls")
        ) {
          this.highlighter(this.$refs.variable_onCallScheduledUrl);
        } else if (
          this.localConfig.introConf.eventOnCallMissed &&
          await this.validator(this.$refs.variable_onCallMissedUrl, 'eventOnCallMissedURL', "checkForUrls")
        ) {
          this.highlighter(this.$refs.variable_onCallMissedUrl);
        } else if (
          this.localConfig.introConf.onVideoUploadingDone &&
          await this.validator(this.$refs.variable_onVideoUploadingDoneUrl, 'onVideoUploadingDoneURL', "checkForUrls")
        ) {
          this.highlighter(this.$refs.variable_onVideoUploadingDoneUrl);
        } else if (
          this.localConfig.introConf.onVideoUploadingFailed &&
          await this.validator(this.$refs.variable_onVideoUploadingFailedUrl, 'onVideoUploadingFailedURL', "checkForUrls")
        ) {
          this.highlighter(this.$refs.variable_onVideoUploadingFailedUrl);
        } else if ( this.localConfig.introConf.redirectOnSuccessful && await this.validator(this.$refs.variable_redirectOnSuccessfulURL,'redirectOnSuccessfulURL', "redirectUrl")) {
          this.highlighter(this.$refs.variable_redirectOnSuccessfulURL);
        } else if(this.eventOnDropURLError || this.eventOnCallConnectURLError || this.eventOnCallCompletedURLError || this.eventOnCallMissedURLError || this.eventOnCallScheduledURLError || this.onVideoUploadingDoneURLError || this.onVideoUploadingFailedURLError) {
          return;
        } else{
          eventBus.$emit("updateConfig", ["callPriority","introConf"]);
          //Setting updatedSuccessfully as the flag sent from the event
          eventBus.$on("updatedSuccessfully", (flag)=>{
            this.updatedSuccessfully = flag;
          });
        }
    }
  },
  beforeRouteUpdate (to, from, next) {
      this.currentFlow = to.params.id
      this.getFlowData()
      next()
  },
  beforeRouteLeave(to, from, next){
    //If not updatedSuccessfully then replace it with the localCopy that was created at the start
    if(!this.updatedSuccessfully){
      //Checking if it's a multiflow setting, then update the main config file accordingly
      if(this.currentFlow != "default"){
        //Using deep copy to reset the config file to it's orginal state before leaving the route
        this.config.multiflow[this.currentFlow].introConf = JSON.parse(JSON.stringify(this.localCopy.introConf));
      } else{
        this.config.introConf = JSON.parse(JSON.stringify(this.localCopy.introConf));
      } 
    }
    next()
  },
  created(){
      this.currentFlow = this.$route.params.id;
      this.getFlowData()
  },
  props: ["config"]
};
</script>