<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col md="8" sm="12" lg="8" pa-2>
        <v-card>
          <v-card-text>
            <span class="title">Create a new manager</span>
            <v-divider></v-divider>
            <p class="mt-3 mb-4 subtitle-2">Please enter the details of the manager:</p>
            <v-form ref="form" v-model="valid" autocomplete="false">
              <v-text-field :rules="rules.email" label="Email Id of manager" v-model="email" outlined></v-text-field>
              <v-text-field :rules="rules.name" label="Name of manager" v-model="name" outlined></v-text-field>
              <v-text-field :rules="rules.mandatory" label="Username" v-model="username" outlined></v-text-field>
              <v-checkbox
                class="mt-0 mb-0"
                v-model="sendPasswordOnEmail"
                label="Do you want to send password over email?"
              ></v-checkbox>
              <v-text-field
                v-if="!sendPasswordOnEmail"
                :rules="rules.password"
                label="Password"
                :type="showPass ? 'text' : 'password'"
                :append-icon="showPass ? 'far fa-eye' : 'far fa-eye-slash'"
                @click:append="showPass = !showPass"
                v-model="password"
                outlined
                autocomplete="new-password"
              ></v-text-field>
              <v-text-field
                maxlength="10"
                counter
                :rules="rules.number"
                label="Phone number"
                type="text"
                v-model="phone"
                outlined
              ></v-text-field>
              <v-checkbox
                  class="mt-0 mb-0"
                  v-model="firstLogin"
                  label="First time password change needed for Manager?"
                ></v-checkbox>
              <div class="text-right">
                <v-btn :disabled="!valid" color="primary" @click="createAgent()">Create Manager</v-btn>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="askPhoneNumber" persistent max-width="500">
      <v-card>
        <v-card-title class="title mb-3">Please add your mobile number</v-card-title>
        <v-card-text>
          <v-text-field
            label="Mobile phone number"
            v-model="phone"
            type="text"
            counter
            :rules="phoneRules"
            maxlength="10"
            outlined
          ></v-text-field>
          <div class="text-right">
            <v-btn color="red darken-1" text @click="askPhoneNumber = false; phone='';">Cancel</v-btn>
            <v-btn color="green darken-1" text @click="updatePhone()">Update</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { end_points, constants } from "@/config";
import enLib from "crypto-js/aes";

export default {
  data: () => ({
    valid: false,
    showPass:false,
    currentUserData: {},
    allManagers: [],
    name: "",
    assignManager: false,
    email: "",
    username: "",
    password: "",
    sendPasswordOnEmail: false,
    firstLogin: false,
    phone: "",
    assigned: {},
    customerId: "",
    phoneRules: [
      v => /^[6-9]{1}[0-9]{9}/.test(v) || "Not a proper mobile number!"
    ],
    askPhoneNumber: false,
    rules: {
      mandatory: [v => /^.+$/.test(v) || "Field cannot be empty!", v => /^[^</>]*$/.test(v) || "Cannot use <,/,>"],
      mandatoryObj: [
        v => /^[0-9A-Za-z]{24}$/.test(v.id) || "Field cannot be empty!"
      ],
      number: [
        v => !!v || "Field cannot be empty!",
        v => /^[6-9]{1}[0-9]{9}/.test(v) || "Not a valid mobile number!"
      ],
      name: [
        v => !!v || "Field cannot be empty!",
        v =>
          /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/.test(v) ||
          "Not a valid name!"
      ],
      email: [
        v => !!v || "Field cannot be empty!",
        v =>
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Not a proper email!"
      ],
      password: [
        v => !!v || "Field cannot be empty!",
        v =>  ((v.length >= 8) && /[a-z]/g.test(v) && /[A-Z]/g.test(v) && /[0-9]/g.test(v) && /[\_\$\#\@\^\!\*\+\)\(\{\}\'\\\?\/\<\>\.\, \^\[\]]/g.test(v))
          || "Password should be minimum 8 characters length including lowerCase, UpperCase, number and special character."
      ]
    }
  }),
  methods: {
    async updatePhone() {
      if (/^[6-9]{1}[0-9]{9}/.test(this.phone)) {
        let dataToPost = {
            phone: this.phone
        };
        dataToPost = enLib.encrypt(JSON.stringify(dataToPost), this.$store.getters.envValues.otpEncKey).toString();

        let res = await axiosInstance.patch(
          end_points.get_customer_data(this.currentUserData.id),
          {
            dataToPost
          }
        );
        if (res.data) {
          this.askPhoneNumber = false;
          eventBus.$emit("vueSnack", "Successfully updated phone number!");
        } else {
          eventBus.$emit("vueSnack", "Something went wrong!");
        }
      } else {
        eventBus.$emit("vueSnack", "Not a valid phone number!");
      }
    },
    async getAllReUnderManager(customerId) {
      try {
        this.allManagers = (
          await axiosInstance.get(end_points.get_all_agents(customerId))
        ).data;
        this.allManagers = this.allManagers
          .filter(item => item.belongsTo == 0)
          .map(item => (item = { text: item.name, value: { ...item } }));
      } catch (error) {
        console.log(error);
        this.allManagers = [];
      }
    },
    clearView() {
      this.username = "";
      this.password = "";
      this.email = "";
      this.name = "";
      this.phone = "";
      this.sendPasswordOnEmail = false;
      this.firstLogin = false;
      this.assigned = {};
      this.assignManager = false;
      this.$refs.form.reset();
    },
    async createAgent() {
      if (this.name && this.email && this.username) {
        try {
          let res = await axiosInstance.post(
            end_points.create_agent(this.customerId),
            {
              name: this.name,
              email: this.email,
              username: this.username,
              phone: this.phone,
              password: this.password || "RandomOne",
              firstLogin: this.firstLogin,
              sendPasswordOnEmail: this.sendPasswordOnEmail,
              belongsTo: this.assignManager ? this.assigned.id : ""
            }
          );
          console.log(res);
          if (res.data) {
            eventBus.$emit("vueSnack", "Successfully created a manager!");
            this.clearView();
            let { userId, token } = this.$store.getters.userData;
            this.getAllReUnderManager(userId);
          } else {
            eventBus.$emit(
              "vueSnack",
              "Something went wrong, please try again!"
            );
          }
        } catch (error) {
          let errRes = error.response.data.error.details;
          let errorToShow = [];
          for (let i in errRes.messages) {
            errorToShow.push(errRes.messages[i]);
          }
          eventBus.$emit("vueSnack", errorToShow.join(", "));
        }
      } else {
        eventBus.$emit("vueSnack", "All the fields are requried!");
      }
    }
  },
  async created() {
    try {
      if (this.$store.getters.userData) {
        let userId;
        if (this.$store.getters.userData.customerData) {
          userId = this.$store.getters.userData.customerData.id;
        } else {
          userId = this.$store.getters.userData.userId
        }
        let { token } = this.$store.getters.userData;
        axiosInstance.defaults.headers.common["Authorization"] = token;
        let resp = (
          await axiosInstance.get(end_points.get_customer_data(userId))
        ).data;
        this.currentUserData = {
          ...this.$store.getters.userData,
          ...resp
        };
        if (!this.currentUserData.phone) {
          //this.askPhoneNumber = true;
        }

        if ( this.currentUserData.customerData ) {
          this.customerId = this.currentUserData.customerData.id
        } else {
          this.customerId = this.currentUserData.userId
        }

        this.$store.commit('setUserData', this.currentUserData);
        await this.getAllReUnderManager(userId);
      } else {
        this.$router.push("/admin");
      }
    } catch (error) {
      console.log(error);
      this.$router.push("/admin");
    }
  }
};
</script>

<style>
</style>
