<template>
  <v-row justify="center" class="primary-text">
    <v-col cols="12" sm="12" md="10" lg="10" xl="10">
      <v-card class="mb-5">
        <v-container>
          <v-form class="pl-5 pr-5" v-on:submit.prevent="submitForm()">
            <div class="headline pb-1">Admin Forms</div>
            <v-divider class="ml-5 mb-3"></v-divider>
            <v-btn
              fixed
              fab
              color="primary"
              top
              right
              style="top: 100px; right: 50px"
              @click="
                localConfig.fields.push({
                  elementType: 'inputField',
                  isRequired: false,
                  conditionalRendering: false,
                  optionalValuesRequired: false,
                  optionalValues: {},
                })
              "
            >
              <v-icon>fas fa-plus</v-icon>
            </v-btn>

            <v-card
              v-for="(field, index) in localConfig.fields"
              :key="index"
              elevation="5"
              style="margin-bottom: 25px; padding: 25px"
            >
              <FormBuilder
                ref="formBuilder"
                :field="field"
                :index="index"
                :fields="localConfig.fields"
              ></FormBuilder>
            </v-card>
            <div class="text-right">
              <v-btn color="primary" @click="submitForm">UPDATE</v-btn>
            </div>
          </v-form>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import FormBuilder from "@/components/FormBuilder.vue";
export default {
  data: () => ({
    fieldTypes: [
      { value: "multiSelectDropdown", text: "Multi-Select dropdown" },
      { value: "dropdown", text: "Dropdown" },
      { value: "inputField", text: "Input Text" },
      { value: "textarea", text: "TextArea" },
    ],
    localConfig: {},
    currentFlow: "",
    isValid: true,
    console,
  }),
  components: {
    FormBuilder: FormBuilder,
  },
  methods: {
    submitForm() {
      if (this.$refs.formBuilder.length < 1) {
        eventBus.$emit(
          "vueSnack",
          "Minimum 1 admin feedback instruction is required! "
        );
        return false;
      } else {
        for (let i = 0; i < this.$refs.formBuilder.length; i++) {
          let labels = Object.keys(this.$refs.formBuilder[i].$refs);
          console.log(labels);
          let gValid = true;
          for (let label of labels) {
            if (label.indexOf("variable") > -1) {
              let valid = this.validateVariableName(
                this.$refs.formBuilder[i].$refs[label].value
              );
              console.log(valid);
              if (valid !== true) {
                this.$refs.formBuilder[i].$refs[label].focus();
                this.$refs.formBuilder[i].$refs[label].blur();
                return false;
              }
            } else if (label.indexOf("label") > -1) {
              let valid = this.validateLabel(
                this.$refs.formBuilder[i].$refs[label].value
              );
              console.log(valid);
              if (valid !== true) {
                this.$refs.formBuilder[i].$refs[label].focus();
                this.$refs.formBuilder[i].$refs[label].blur();
                return false;
              }
            } else if (label.indexOf("options") > -1) {
              if (
                this.$refs.formBuilder[i] &&
                this.$refs.formBuilder[i].$refs[label]
              ) {
                let valid = this.validateOptions(
                  this.$refs.formBuilder[i].$refs[label].value
                );
                if (valid !== true) {
                  this.$refs.formBuilder[i].$refs[label].focus();
                  this.$refs.formBuilder[i].$refs[label].blur();
                  return false;
                }
              }
            } else if (label.indexOf("conditionalRenderVariable") > -1) {
              if (this.$refs.formBuilder[i].$refs[label]) {
                let valid = this.validateConditionalRender(
                  this.$refs.formBuilder[i].$refs[label].selectedItems
                );
                console.log(valid);
                if (valid !== true) {
                  console.log(this.$refs.formBuilder[i].$refs[label]);
                  this.$refs.formBuilder[i].$refs[label].focus();
                  // For some reasons, was unable to highlight v-select element
                  // this.$refs.formBuilder[i].$refs[label].blur();
                  return false;
                }
              }
            } else if (label.indexOf("conditionalValue") > -1) {
              let value;
              if (
                this.$refs.formBuilder[i].$refs[label] &&
                !this.$refs.formBuilder[i].$refs[label].value
              ) {
                this.$refs.formBuilder[i].$refs[label].focus();
                this.$refs.formBuilder[i].$refs[label].blur();
                return false;
              }
              this.$nextTick(() => {
                if (this.$refs.formBuilder[i].$refs[label].value) {
                  value = this.$refs.formBuilder[i].$refs[label].value;
                } else if (
                  this.$refs.formBuilder[i].$refs[label].initialValue
                ) {
                  value = this.$refs.formBuilder[i].$refs[label].initialValue;
                }
                let valid = this.validateLabel(value);
                console.log(valid);
                if (valid !== true) {
                  this.$refs.formBuilder[i].$refs[label].focus();
                  this.$refs.formBuilder[i].$refs[label].blur();
                  return false;
                }
              });
            } else if (label.startsWith("optionalValue_")) {
              let validArr = [];
              let objKeysForRef = Object.keys(
                this.$refs.formBuilder[i].$refs
              ).filter((key) => key.startsWith("optionalValue_"));
              objKeysForRef.forEach((ele) => {
                if (
                  this.$refs.formBuilder[i].$refs[ele].length > 0 &&
                  /^\s+$/.test(this.$refs.formBuilder[i].$refs[ele][0].value)
                ) {
                  this.$refs.formBuilder[i].$refs[ele][0].focus();
                  this.$refs.formBuilder[i].$refs[ele][0].blur();
                  validArr.push(false);
                } else {
                  validArr.push(true);
                }
              });
              if (validArr.includes(false)) {
                return false;
              }
            }
          }
        }
        eventBus.$emit("updateConfig", ["fields"]);
        return true;
      }
    },
    validateVariableName(val) {
      if (!val || !val.length) {
        return "Variable name can't be left empty";
      }

      if (/^[0-9]/.test(val)) {
        this.isValid = false;
        return "Variable name can't start with a number";
      }
      if (!/^[a-zA-Z0-9_]+$/.test(val)) {
        return "Invalid Characters Present (alphanumeric only)";
      }

      let counter = 0;
      for (let i = 0; i < this.localConfig.fields.length; i++) {
        let v = this.localConfig.fields[i].variable;
        if (v == val) counter++;
        if (counter > 1) {
          this.isValid = false;
          return "This variable already exists";
        }
      }

      this.isValid = true;
      return true;
    },

    validateOptions(val) {
      console.log(val);
      if (!val || !val.length) {
        this.isValid = false;
        return "At least one option is required";
      }

      return true;
    },

    validateLabel(val) {
      if (!val || !val.length) {
        return "Label can't be left empty";
      }

      return true;
    },
    validateConditionalRender(val) {
      console.log(val);
      if (!val || val.length === 0) {
        return "Conditional Render needs a varaible";
      }
      return true;
    },
    getFlowData() {
      if (this.currentFlow != "default") {
        this.localConfig = this.config.multiflow[this.currentFlow];
      } else {
        this.localConfig = this.config;
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.currentFlow = to.params.id;
    this.getFlowData();
    next();
  },
  beforeMount() {
    this.currentFlow = this.$route.params.id;
    this.getFlowData();
  },
  created() {
    this.currentFlow = this.$route.params.id;
    this.getFlowData();
  },
  props: ["config"],
};
</script>
