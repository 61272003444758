var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"primary-text",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"10","lg":"10","xl":"10"}},[_c('v-card',{staticClass:"mb-5"},[_c('v-container',[_c('v-form',{staticClass:"pl-5 pr-5",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm()}}},[_c('div',{staticClass:"headline pb-1"},[_vm._v("Admin Forms")]),_c('v-divider',{staticClass:"ml-5 mb-3"}),_c('v-btn',{staticStyle:{"top":"100px","right":"50px"},attrs:{"fixed":"","fab":"","color":"primary","top":"","right":""},on:{"click":function($event){return _vm.localConfig.fields.push({
                elementType: 'inputField',
                isRequired: false,
                conditionalRendering: false,
                optionalValuesRequired: false,
                optionalValues: {},
              })}}},[_c('v-icon',[_vm._v("fas fa-plus")])],1),_vm._l((_vm.localConfig.fields),function(field,index){return _c('v-card',{key:index,staticStyle:{"margin-bottom":"25px","padding":"25px"},attrs:{"elevation":"5"}},[_c('FormBuilder',{ref:"formBuilder",refInFor:true,attrs:{"field":field,"index":index,"fields":_vm.localConfig.fields}})],1)}),_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.submitForm}},[_vm._v("UPDATE")])],1)],2)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }