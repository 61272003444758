<template>
  <v-container>
    <p class="headline" style="color:#126DCB;font-size:26px;">MIS Reports</p>
    <div>
        <v-card class="mx-auto" max-width="700" style="padding:40px;">
            <v-card-text>
                <p class="display-1 text--primary" style="text-align:center;font-size:24px !important;">
                    Download New Report
                </p>
            </v-card-text>
            <v-row >
                <v-col>
                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="startDate"
                    transition="scale-transition" offset-y max-width="300px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="startDate" label="From" prepend-icon="mdi-calendar"
                            readonly v-bind="attrs" v-on="on">
                        </v-text-field>
                    </template>
                    <v-date-picker v-model="startDate" :max="todayDate" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">
                            Cancel
                        </v-btn>
                        <v-btn text color="primary" @click="()=>{$refs.menu.save(startDate); endDate=maxDate()}">
                            OK
                        </v-btn>
                    </v-date-picker>
                </v-menu>
                </v-col>
            <!-- <v-spacer></v-spacer> -->
                <v-col>
                <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" :return-value.sync="endDate"
                    transition="scale-transition" offset-y max-width="300px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="endDate"  label="To" prepend-icon="mdi-calendar"
                            readonly v-bind="attrs" v-on="on">
                        </v-text-field>
                    </template>
                    <v-date-picker v-model="endDate" :min="startDate" :max="maxDate()"  no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text  color="primary" @click="menu1 = false">
                            Cancel
                        </v-btn>
                        <v-btn text color="primary" @click="$refs.menu1.save(endDate)">
                            OK
                        </v-btn>
                    </v-date-picker>
                </v-menu>
                </v-col>
        </v-row>
        <v-row >
                <v-col >
                <v-card-text>
                <p class="display-1 text--primary" style="text-align:left;font-size:16px !important;">
                    Select type of reports to download:
                </p>
                </v-card-text>
                </v-col> 
                <v-col >
                <v-autocomplete
                v-model="selectedTypes"
                :rules="rules.checkEmpty"
                :items="typesOfReport"
                  ></v-autocomplete>
                </v-col>
                
        </v-row>
        <v-row>
            <v-card-text>
            <p style="color:#8E8E8E;font-size:14px;text-align:center !important;">Note: The date duration should not be more than 3 months.</p>
            </v-card-text>
        </v-row>
        <v-row justify="center">
            <v-btn :disabled="!enableDownloadBtn" rounded color="primary" class="ma-2" @click="downloadMis()">Download</v-btn>
        </v-row>
        </v-card>
    </div>
    
  </v-container>
</template>

<script>
import { end_points } from "@/config";
import {report_url,mis_endPoints} from "@/config";
let snackBar = window.snackBar;

let axios= require("axios");
export default {
  data: () => ({
      startDate: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu1: false,
      todayDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      userId :null,
      enableDownloadBtn: true,
      //TODO: "Status summary","Analysis by session status","Agent Productivity","Post call agent feedback summary" 
      typesOfReport:["Raw data", "agentProductivity"],
      selectedTypes: "Raw data",
      rules:{
        checkEmpty : [
            (value) =>
            !(value.length < 1) ||
            "Minimum one report needs to be selected!"
        ]
      }
    }),
  methods: {
    async downloadMis(){
        this.enableDownloadBtn = false;
        let reqData = {
            customerId: this.userId,
            startDate: this.startDate,
            endDate: this.endDate,
            typesOfReport: this.selectedTypes
        }
        let { token } = this.$store.getters.userData;
        eventBus.$emit("vueLoader", true);
        axios.post(`${report_url}${mis_endPoints.create}`,reqData,
        {     
            headers: {'Authorization': token},
            responseType: "blob" 
        }).then((response)=>{
            this.enableDownloadBtn = true;
            eventBus.$emit("vueLoader", false);
            if(response.status ===200 ){
                const blob = new Blob([response.data], {
                    type: response.headers["content-type"]
                })  
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = `report.zip`;
                link.click();
                URL.revokeObjectURL(link.href);
            }
            else{
                snackBar("No records found for given date range");
            }
        }).catch((err) => {
            this.enableDownloadBtn = true;
            eventBus.$emit("vueLoader", false);
            console.log("Something went wrong::::", err);  
        });
      },
    maxDate() {
        let s = new Date(this.startDate)
        let maxDate = new Date(s.getFullYear(),s.getMonth()+3,s.getDate()) 
        let todayDate = new Date();
        if (todayDate > maxDate) {
            return maxDate.toISOString().substr(0,10);
        } else {
            return todayDate.toISOString().substr(0,10);
        }
    }
  },
  beforeDestroy() {},
  created() {
    if (this.$store.getters.userData.customerData) {
        this.userId = this.$store.getters.userData.customerData.id;
    } else {
        this.userId = this.$store.getters.userData.userId;
    }
    this.currentUserData = this.$store.getters.userData;
  },
};
</script>

<style scoped>

</style>