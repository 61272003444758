<template>
  <v-container>
    <p class="headline">All Users</p>
    <span>Select a manager to view agents</span>
    <v-row v-if="managers.length != 0">
      <v-col sm="12" cols="12" md="5" xl="4" lg="4" pa-2 class="scrollAudit">
        <v-card v-for="(item, i) in managers" :key="i" class="ma-2">
          <v-card-text class="mb-0 pb-0">
            <div class="float-right">
              <v-btn icon color="signzy_blue" @click="showDetails(item)">
                <v-icon>fas fa-chevron-circle-right</v-icon>
              </v-btn>
            </div>
            <span class="title">{{ item.name }}</span>
            <br />
            <span class="body-2">{{ item.email }}</span>
            <br />
            <span class="body-2">@{{ item.username }}</span>
          </v-card-text>
          <v-card-actions>
            <v-btn
              small
              outlined
              block
              color="signzy_blue"
              @click="showResetDiag(item)"
              >Reset Password</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col sm="12" cols="12" md="7" xl="8" lg="8" pa-2>
        <div class="agentPanel" v-if="backopAgentId.username">
          <p class="headline">
            All agents under
            <b>{{ backopAgentId.name }}</b>
          </p>
          <span v-if="backopAgentId.agents.length == 0">No agents found!</span>
          <v-row v-else align="center">
            <v-col
              sm="12"
              cols="12"
              md="6"
              xl="6"
              lg="6"
              pa-2
              v-for="(item, i) in backopAgentId.agents"
              :key="i"
            >
              <v-card>
                <v-card-text class="mb-0 pb-0">
                  <v-row align="center" justify="center">
                    <v-col sm="12" cols="12" md="4" xl="4" lg="4" pa-2>
                      <img
                        :src="getInitialAvatar(item.name)"
                        style="width: 100%"
                      />
                    </v-col>
                    <v-col sm="12" cols="12" md="8" xl="8" lg="8" pa-2>
                      <span class="title">{{ item.name }}</span>
                      <br />
                      <span class="subtitle-1">{{ item.username }}</span>
                      <br />
                      <span class="subtitle-2">{{ item.email }}</span>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    small
                    outlined
                    color="signzy_blue"
                    @click="showResetDiag(item)"
                    >Reset Password</v-btn
                  >
                  <v-spacer></v-spacer>
                  <v-btn icon color="primary" @click="editAgent(item)">
                    <v-icon small>fas fa-edit</v-icon>
                  </v-btn>
                  <v-btn icon color="red" @click="deleteAgent(item.id)">
                    <v-icon small>fas fa-trash</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <div v-else>
      <p class="title">No customer admin is found!</p>
    </div>
    <v-dialog v-model="editAgentDialog" persistent max-width="500">
      <v-card>
        <v-card-title>
          <span class="title">
            Updating manager for {{ currentAgentData.name }}
          </span>
          <v-spacer></v-spacer>
          <v-btn icon color="red" @click="editAgentDialog = false; assigned = {}; skillTag = null">
            <v-icon small>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="assigned"
            :items="allManagers"
            label="Assign another manager"
            outlined
          ></v-select>
          <span
            v-if="hasSkills"
            class="title"
          >Updating Skill Tag</span>
          <v-select
            v-if="hasSkills && !skillError && skillSets.length > 0"
            v-model="assignedSkills"
            :items="skillSets"
            label="Edit Skill Tag"
            multiple
            outlined
          ></v-select>
          <p v-if="hasSkills && !skillError && skillSets.length < 1">You don't have any skills added</p>
          <p
            v-if="hasSkills && skillError"
            style="color: red"
            >Couldn't fetch skills</p>
          <div class="text-right">
            <v-btn color="primary darken-1" dark @click="updateDetails()"
              >Update</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="resetConfirmation" persistent max-width="500">
      <v-card>
        <v-card-title>
          <span class="title"
            >Reset Password for {{ currentResetUser.name }} ?</span
          >
          <v-spacer></v-spacer>
          <v-btn icon color="red" @click="resetConfirmation = false">
            <v-icon small>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="text-right">
            <v-btn
              color="primary darken-1"
              dark
              @click="resetPassword(currentResetUser)"
              >Reset</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { end_points } from "@/config";
import signzyAvatar from "@/Plugins/SignzyAvatarGenerator.js";
import { getSkills } from "@/flux/skills.flux";

export default {
  data: () => ({
    agents: [],
    customerUserId: null,
    backopAgentId: {},
    assigned: {},
    isCallListVisible: true,
    currentUserData: {},
    editAgentDialog: false,
    currentAgentData: {},
    resetConfirmation: false,
    currentResetUser: {},
    managers: [],
    skillSets: [],
    skillError: false,
    hasSkills: false,
    allManagers: [],
    unallocated: [],
    skillTag: null,
    assignedSkills:[],
    status: "pending",
  }),
  methods: {
    showResetDiag(user) {
      this.currentResetUser = user;
      this.resetConfirmation = true;
    },
    async resetPassword(user) {
      let options = {
        method: "POST",
        url: end_points.resetPass(this.customerUserId, user.id),
        body: {
          resetPassword: true,
        },
      };

      try {
        let resp = await axiosInstance(options);
        let data = resp.data;
        console.log("RESET STS", data.status);
        if (data.status == "success") {
          eventBus.$emit("vueSnack", "Password Reset mail sent successfully");
          this.resetConfirmation = false;
          this.currentResetUser = {};
        }
      } catch (err) {
        console.error("ERROR WHILE RESET PASS", err);
        eventBus.$emit("vueSnack", "Could not reset password");
        this.resetConfirmation = false;
        this.currentResetUser = {};
      }
    },
    async updateDetails() {
      let response = await axiosInstance.get(end_points.get_agent_data(this.currentAgentData.id));
      let currentUserData = response?.data ?? this.currentAgentData;
      let skillTag = currentUserData?.skillTag;
      if (!skillTag)
        skillTag = this.assignedSkills[0] ? this.assignedSkills[0]: "";
      if (this.assigned.id || this.assignedSkills) {
        let res = await axiosInstance.patch(
          end_points.get_agent_data(this.currentAgentData.id),
          {
            belongsTo: this.assigned.id ? this.assigned.id : this.currentAgentData.belongsTo,
            skillTag,
            assignedSkills : this.assignedSkills ? this.assignedSkills : this.currentAgentData.assignedSkills ? this.currentAgentData.assignedSkills:""
          }
        );
        await this.getPendingBackopUsers();
        this.backopAgentId = {};
        this.editAgentDialog = false;
        eventBus.$emit("vueSnack","Successfully updated!");
      } else {
        eventBus.$emit("vueSnack","Nothing to update!");
      }
      this.assigned = {};
      this.skillTag = null;
    },
    getInitialAvatar(name) {
      return signzyAvatar({ name });
    },
    async editAgent(agent) {
      this.assignedSkills = agent?.assignedSkills ?? [];
      this.editAgentDialog = true;
      this.currentAgentData = agent;
    },
    showDetails(agent) {
      console.log("done calling agent!!");
      this.backopAgentId = agent;
      let tmpAgents = this.agents.filter((item) => item.belongsTo == agent.id);
      this.$set(this.backopAgentId, "agents", tmpAgents);
    },
    async deleteAgent(agentId) {
      try {
        if (confirm("Are you sure, you want to delete?")) {
          console.log("Deleting Agent, AgentId: "+agentId);

          let res = await axiosInstance.delete(
            end_points.delete_customer_agent(
              this.customerUserId,
              agentId
            )
          );
          eventBus.$emit("vueSnack", "Successfully deleted!");
          await this.getPendingBackopUsers();
          //console.log("done calling!!");
          this.showDetails(this.backopAgentId);
        }
      } catch (error) {
        console.log("Error while deleting Agent!: "+error);
        eventBus.$emit("vueSnack", "Error while deleting Agent!");
      }
    },
    async getPendingBackopUsers() {
      try {
        let res = await axiosInstance.get(
          end_points.get_all_agents(this.customerUserId)
        );
        if (res.data) {
          this.agents = res.data;
          this.managers = this.agents.filter((item) => item.belongsTo == 0 && (item.role == undefined || item.role !== "admin"));
          this.unallocated = this.agents.filter(
            (item) => item.belongsTo == undefined
          );
          this.allManagers = res.data
            .filter((item) => item.belongsTo == 0 && item.role != "admin")
            .map((item) => (item = { text: item.name, value: { ...item } }));
          if (this.unallocated.length != 0) {
            this.managers.push({
              id: undefined,
              name: "Unallocated",
              email: "N/A",
              username: "N/A",
            });
          }
        } else {
          eventBus.$emit("vueSnack", "No Pending audits!");
        }
      } catch (error) {
        console.log(error);
        eventBus.$emit("vueSnack", "Something went wrong!");
      }
    },
  },
  beforeDestroy() {
    eventBus.$off("loadPendingAudit");
  },
  async created() {
    this.currentUserData = this.$store.getters.userData;
    if ( this.currentUserData.customerData ) {
      this.customerUserId = this.currentUserData.customerData.id
    } else {
      this.customerUserId = this.currentUserData.userId
    }
    this.getPendingBackopUsers();
    // Fetching SKills here
    await getSkills({customerId: this.customerUserId}).then((res) => {
      this.hasSkills = res.hasSkills ? res.hasSkills : false;
      this.skillError = !res.success;
      this.skillSets = res.skillSets ? res.skillSets : [];

    }).catch((err) => {
      console.log("ListUsers, Error while checking getSkills for CustomerId: ", this.customerUserId+", Error: "+err);
      this.hasSkills = false;
      this.skillError = true;
      this.skillSets = [];
    })
  },
};
</script>

<style scoped>
.scrollAudit {
  overflow: scroll;
  height: 630px;
}
.agentPanel {
  overflow: scroll;
  height: 75vh;
}
</style>
